<script>

import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required, maxLength, minLength
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Restaurant Brand",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,

  },
  data() {
    return {
      title: "Add Restaurant Brand",
      items: [
        {
          text: "Restaurant Brand",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      value: null,
      branchName: null,
      foodType: [],
      branchIDSel: null,
      status: '',
      submitted: false,
      address: '',
      encodeImage: '',
      bannerImage: "",
      restID: 0,
      isEdit: false,
      alertMsg: "Restaurant Registered Successfully!",

      foodOptions: [],
      // deliveryTimeArr: ["20 min","45 min","1 hour"],
      cuisineList: [],
      branches: [],
      companyList: [],
      ownerList: [],
      maxlen: "",
      data: "",
      option: "",
      textarea: "",
      tagline: null,
      disabled: true,
      disabledOwner: false,
      imageData: '',
      ownerID: '',
      showDismissibleAlert: false,
      loading: false,
      bannerImageData: '',
      test: [{"cuisineID": 2, "cuisineName": "Chinese"}, {"cuisineID": 1, "cuisineName": "Punjabi"}, {
        "cuisineID": 3,
        "cuisineName": "Italian"
      }],
      restData: [],

      posts: {
        restName: "",
        tagline: "",
        foodType: [],
        value: '',
        adminName: '',
        adminEmail: '',
        adminPhone: '',
        imageData: '',
        colorCode: '#000000',
        contactNO: "",
        website: "",
        cuisineType: [],
        empType: false,
        password: "",
        companyList: [],
        ownerList: [],
        companyID: "",
        ownerID: '',
      }
    };
  },

  validations: {
    posts: {
      restName: {
        required,
      },
      foodType: {
        required,
      },
      companyID: {
        required,
      },
      price: {
        required,
      },
      cuisineType: {
        required,
      },
      // ownerID:{
      //   required:function() {
      //     //alert(this.posts.ownerID);
      //        return (this.posts.empType==false && this.posts.ownerID=="") ? false : true;
      //   },
      // },
      // adminName:{
      //   required:function() {
      //       return (this.posts.empType==true && this.posts.adminName=="") ? false : true;
      //   },
      // },
      // adminEmail:{
      //
      //   required:function() {
      //              return (this.posts.empType==true && this.posts.adminEmail=="") ? false : true;
      //   },
      //    email,
      // },
      // adminPhone:{
      //   required:function() {
      //             return (this.posts.empType==true && this.posts.adminPhone=="") ? false : true;
      //   },
      //   maxLength: maxLength(10),
      //   minLength: minLength(10),
      // },
      contactNO: {
        maxLength: maxLength(10),
        minLength: minLength(10),
      }
    },

  },
  methods: {

    submitRestaurantForm(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        /*
             Add the form data we need to submit
         */
        formData.append('restID', this.restID);  // for edit
        formData.append('restName', this.posts.restName);
        formData.append('tagline', this.posts.tagline);
        formData.append('empType', this.posts.empType);
        formData.append('foodType', JSON.stringify(this.posts.foodType));
        formData.append('cuisineType', JSON.stringify(this.posts.cuisineType));
        formData.append('website', this.posts.website);
        formData.append('priceForTwo', this.posts.price);
        formData.append('contactNO', this.posts.contactNO);
        formData.append('companyID', JSON.stringify(this.posts.companyID));
        formData.append('document', this.encodeImage);
        formData.append('bannerImage', this.bannerImage);
        e.preventDefault();
        let apiName = "add-restaurant-brand";
        let routerPush = "restaurantBranchForm";
        if (this.isEdit == true) {
          apiName = "update-restaurant-form";
          routerPush = "restaurantTable";
        }

        this.axios.post(this.$loggedRole + "/" + apiName, formData,
            {
              'Content-Type': 'multipart/form-data'
            })
            .then((result) => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              // this.status = result.data.data;
              //    this.showDismissibleAlert = true;

              setTimeout(() => {
                this.$router.push({name: routerPush});
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                title: 'Oops...',
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getAllBranchDetails() {
      this.axios.get(this.$loggedRole + "/getAllBranchDetails")
          .then((result) => {
            this.branches = result.data.data;
          });
    },
    getCompanyList() {
      this.axios.get(this.$loggedRole + "/getCompanyList")
          .then((result) => {
            this.companyList = result.data.companyArr;
          });
    },
    // getOwnerList(){
    //   this.axios.get(this.$loggedRole+"/getOwnerList")
    //       .then((result)=>{
    //         this.ownerList = result.data.ownerArr;       
    //     });
    // },
    getCuisineList() {
      this.axios.get(this.$loggedRole + "/getCuisineList")
          .then((result) => {
            this.cuisineList = result.data.data;

          });
    },
    getFoodTypeList() {
      this.axios.get(this.$loggedRole + "/getFoodTypeList")
          .then((result) => {
            this.foodOptions = result.data.data;

          });
    },
    getEmployeesByCompany() {
      this.axios.post(this.$loggedRole + "/getEmployeesByCompany", {'companyID': this.posts.companyID.companyID}
      )
          .then((result) => {
            this.ownerList = result.data.ownerArr;

          });
    },
    changeColor(color) {
      const {rgba: {r, g, b, a}} = color;
      this.posts.colorCode = `rgba(${r, g, b, a})`;
    },
    previewImage: function (event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];


      }

    },
    previewBannerImage: function (event) {
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {

          this.bannerImageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.bannerImage = input.files[0];

      }
    },
    registerNew() {
      // this.posts.showAlert = 1;
      if (this.disabled) {
        this.disabled = false;
        this.disabledOwner = true;
        this.posts.ownerID = "";
      } else {
        this.disabled = true;
        this.disabledOwner = false;
        this.posts.adminEmail = "";
        this.posts.adminPhone = "";
        this.posts.adminName = "";
      }

    },


    getRestaurantDataByID() {
      this.axios.get(this.$loggedRole + "/restaurant-brand/" + this.restID + "/edit",
      )
          .then((result) => {
            this.restData = result.data.data;
            this.posts.tagline = this.restData.tagline;
            this.posts.foodType = this.restData.foodTypeArr;
            this.posts.cuisineType = this.restData.cuisineArr;
            this.posts.price = this.restData.pricePerHead;
            this.posts.contactNO = this.restData.contactNO;
            this.posts.website = this.restData.website;
            this.posts.restName = this.restData.brandName;
            this.posts.companyID = this.restData.comapanyData;
            this.imageData = this.restData.image;
            this.bannerImageData = this.restData.bannerUrl;

          });
    }


  },

  mounted() {
    this.posts.companyID = this.companyList.find(option => option.companyID === 7);
    this.getAllBranchDetails();
    this.getCuisineList();
    this.getFoodTypeList();
    this.getCompanyList();


    if (this.$route.params.type == "edit") {
      if (this.$route.params.id) {
        sessionStorage.setItem('restID', this.$route.params.id);
      }
    } else {
      sessionStorage.setItem('restID', 0);
    }
    this.restID = sessionStorage.getItem('restID')
    if (this.restID > 0) {
      this.getRestaurantDataByID(this.restID);
      this.isEdit = true;

    }


  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        {{ alertMsg }}
      </b-alert>
    </div>

    <form @submit.prevent="submitRestaurantForm" method="post">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 row">
                  <div class="mb-3 col-md-4">
                    <label class="form-label" for="formrow-restName-input">Restaurant Logo</label>
                    <div class="file-upload-form">
                      Choose File
                      <input type="file" @change="previewImage" class="uploadInput" accept="image/*">
                    </div>

                  </div>
                  <div class="image-preview col-md-3" v-if="imageData.length > 0">
                    <img class="preview rounded-circle" :src="imageData">
                  </div>

                </div>
                <div class="row col-md-6 ">
                  <div class="mb-3 col-md-4">
                    <label class="form-label" for="formrow-restName-input">Restaurant Banner</label>
                    <div class="file-upload-form">
                      Choose File
                      <input type="file" @change="previewBannerImage" class="uploadInput" accept="image/*">
                    </div>
                  </div>
                  <!-- <div class="image-preview col-md-3" v-if="bannerImageData.length > 0">
                      <img class="preview rounded-circle" :src="bannerImageData">
                  </div> -->


                  <div class="col-md-8" v-if="bannerImageData.length > 0">
                    <div class="strip" style="max-width:270px;box-shadow: 0 0 24px 0 rgb(46 51 51 / 10%);">
                      <figure style="position: relative;">

                        <img :src="bannerImageData" class="img-fluid bannerImg" alt="">
                        <span class="ribbonFoodType">
                                            <div class="foodType align-center" :style="{background : foodType.color}"
                                                 v-for="foodType in posts.foodType" v-bind:key="foodType.foodTypeName">
                                                        {{ foodType.shortCode }}
                                            </div>
                                         </span>
                      </figure>

                      <div style="padding: 0px 10px 7px 10px;">
                        <a href="#0"><h3 class="cateresTitle">{{ posts.restName }}</h3></a>
                        <span v-for="(test, index) in posts.cuisineType" :key="index">
                                                <span class="cateresMenuType one-line-text">{{ test.cuisineName }} <span
                                                    v-if="index+1 < posts.cuisineType.length">, </span></span>
                                                </span>
                        <div class="rate-rating">
                          <span class="cateresPrice" v-if="posts.price">₹{{ posts.price }} per head</span>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /strip grid -->


                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-restName-input">Restaurant Brand Name *</label>
                  <input type="text" class="form-control" id="formrow-restName-input" name="restName"
                         v-model="posts.restName"
                         :class="{
                            'is-invalid': submitted && $v.posts.restName.$error,
                          }">
                  <div
                      v-if="submitted && $v.posts.restName.$error"
                      class="invalid-feedback"
                  >
                          <span v-if="!$v.posts.restName.required"
                          >This value is required.</span
                          >
                  </div>
                </div>
                <div class="mb-3 col-md-6">
                  <label>Select Parent Food Company *</label>
                  <multiselect v-model="posts.companyID" :options="companyList" track-by="companyID" label="companyName"
                               :class="{
                              'is-invalid': submitted && $v.posts.companyID.$error,
                            }"></multiselect>
                  <div
                      v-if="submitted && $v.posts.companyID.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.posts.companyID.required"
                            >This value is required.</span
                            >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-tagline-input">Tagline</label>
                  <input type="text" :maxlength="80" class="form-control" id="formrow-tagline-input" name="tagline"
                         v-model="posts.tagline">

                  <p style="right: 9px;" v-if="posts.tagline" class="badge position-absolute" :class="{ 'bg-success': posts.tagline.length !== 80,
                            'bg-danger': posts.tagline.length === 80 }">

                    {{ posts.tagline.length }} / 80
                  </p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-price-input">Avg price per head *</label>
                  <div class="input-group">
                    <div class="input-group-text">₹</div>

                    <input type="number" class="form-control" id="formrow-price-input" min="0"
                           v-model="posts.price" :class="{
                              'is-invalid': submitted && $v.posts.price.$error,
                            }">
                    <div
                        v-if="submitted && $v.posts.price.$error"
                        class="invalid-feedback"
                    >
                            <span v-if="!$v.posts.price.required"
                            >This value is required.</span
                            >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="mt-3">Food Type *</label>
                  <multiselect v-model="posts.foodType" :options="foodOptions" track-by="typeID" label="foodTypeName"
                               :multiple="true" :class="{
                              'is-invalid': submitted && $v.posts.foodType.$error,
                            }"></multiselect>
                  <div
                      v-if="submitted && $v.posts.foodType.$error"
                      class="invalid-feedback"
                  >
                            <span v-if="!$v.posts.foodType.required"
                            >This value is required.</span
                            >
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="mt-3">Speciality *</label>
                  <multiselect v-model="posts.cuisineType" :options="cuisineList" track-by="cuisineID"
                               label="cuisineName" :multiple="true" :class="{
                                'is-invalid': submitted && $v.posts.cuisineType.$error,
                              }"></multiselect>
                  <div
                      v-if="submitted && $v.posts.cuisineType.$error"
                      class="invalid-feedback"
                  >
                              <span v-if="!$v.posts.cuisineType.required"
                              >This value is required.</span
                              >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-website-input">Website</label>
                  <input type="text" class="form-control" id="formrow-website-input" v-model="posts.website"

                  >

                </div>
                <div class="col-md-6 mb-4">
                  <label class="form-label" for="input-contactNO">Contact NO. </label>
                  <input type="number" id="input-contactNO" class="form-control"
                         v-model="posts.contactNO" :class="{
                                'is-invalid': submitted && $v.posts.contactNO.$error,
                              }"
                  >
                  <div
                      v-if="submitted && $v.posts.contactNO.$error"
                      class="invalid-feedback"
                  >
                         <span v-if="!$v.posts.contactNO.maxLength"
                         >This value is too long. It should have 10 digits</span
                         >
                    <span v-if="!$v.posts.contactNO.minLength"
                    >This value length is invalid. It should be
                              10 digits</span
                    >
                  </div>
                </div>
              </div>
              <div class=" row" style="float:right;">
                <b-spinner v-if="loading"
                           class="m-2 col-3"
                           variant="primary"
                           role="status"
                ></b-spinner>

                <button v-if="!isEdit && $can('create restaurant brand')" type="submit"
                        class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit
                </button>
                <button v-if="isEdit && $can('update restaurant brand')" type="submit"
                        class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Layout>
</template>
<!-- SPECIFIC CSS -->
<style scoped src="../../../assets/css/listing.css"></style>
<style scoped src="../../../assets/css/detail-page.css"></style>

<style lang="scss" scoped>

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.file-upload-form {
  padding: 5px 10px;
  background: #ececec;
  border: 1px solid #ececec;
  position: relative;
  color: black;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
}

.uploadInput {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}


// card css////
.cateresTitle {
  font-size: 16px;
  color: #222;
  margin-bottom: 5px;
  margin-top: 10px;
}

.cateresMenuType {
  margin-bottom: 5px;
  font-size: 14px;
  color: #777;
}


.bannerImg {
  width: 270px;
  height: 160px;
  object-fit: cover;
  // box-shadow: aliceblue;
  // box-shadow: 2px 4px 12px -4px #7b7b7bc4;
}

.foodType {
  border-radius: 13px;
  width: 25px;
  height: 25px;
  color: #fff;
  padding-top: 6px;
  display: inline-block;
  font-size: 10px;
  margin-right: 2px;
  text-align: center;
}

.ribbonFoodType {
  color: #fff;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  left: 7px;
  z-index: 9;
}
</style>


